import React from "react";
import "./ContactMe.css";
import { Element } from "react-scroll";

function ContactMe() {
  return (
    <Element name="contact">
    <h1>Email: </h1>
    </Element>
  );
}

export default ContactMe;
